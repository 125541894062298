export const home = {
    'DEVELOPMENT.WARNING.BANNER.MESSAGE':
        'Please be aware that this a Test Version! To access the Production version please follow: <a> https://confirmations.mazars.global</a>',
    'DEVELOPMENT.WARNING.BANNER.MAINTENANCE': '<b>⚠️ Scheduled Maintenance</b><br></br>The application will be unavailable on <b>Monday, 25th November from 6 pm to 8 pm</b>. Thank you for your understanding.',
    'TRANSLATOR.SELECT': 'Select your language',
    'MENU.HOME': 'Home',
    'NAVBAR.WELCOME': 'Hi',
    'NAVBAR.SIGNOUT': 'Sign Out',
    'NAVBAR.SWITCH.PROFILE': 'Switch profile',
    'NAVBAR.SELECT.AUDITOR': 'Auditor',
    'NAVBAR.SELECT.ADMIN': 'Admin',
    'NAVBAR.SELECT.SUPPORT': 'Support',
    'NAVBAR.SELECT.CLIENT': 'Client',
    'NAVBAR.LANGUAGE.SELECT': 'Select Language',
    'HOME.TITLE': 'Home',
    'HOME.ENGAGEMENT.BUTTON.CREATE': 'New engagement',
    'HOME.ENGAGEMENT.FILTER.ARCHIVED': 'archived Engagements',
    'HOME.ENGAGEMENT.FILTER.OPEN': 'open Engagements',
    'HOME.USER.ENGAGEMENT.ARCHIVED.ERROR': 'User engagements archived error',
    'HOME.ENGAGEMENT.ARCHIVE.ERROR': 'Engagement archive error',
    'HOME.ENGAGEMENT.ARCHIVE.SUCCESS': 'Engagement (un)archived',
    'HOME.KPI.ENGAGEMENTS': 'Engagements',
    'HOME.KPI.CLIENTS': 'Entities',
    'HOME.KPI.CONFIRMATIONS.CREATED': 'Confirmations created',
    'HOME.KPI.CONFIRMATIONS.SENT': 'Confirmations sent',
    'HOME.TABLE.TITLE.ENGAGEMENT': 'Engagement',
    'HOME.TABLE.TITLE.CLOSING_DATE': 'Closing Date',
    'HOME.TABLE.TITLE.NB_COMPANIES': 'Entities',
    'HOME.TABLE.TITLE.NB_CONFIRMATIONS': 'Confirmations',
    'HOME.TABLE.TITLE.USERS': 'Auditors',
    'HOME.TABLE.TITLE.PROGRESS': 'Progress',
    'HOME.TABLE.SUBTITLE.PROGRESS': 'Progress',
    'HOME.TABLE.PROGRESS.NOT.STARTED': 'Not started',
    'HOME.TABLE.ACTION.TOOLTIP.SETTINGS': 'Engagement settings',
    'HOME.TABLE.ACTION.TOOLTIP.NEW': 'New confirmation',
    'HOME.TABLE.ACTION.TOOLTIP.DOWNLOAD': "Download engagement's documents",
    'HOME.TABLE.ACTION.TOOLTIP.DELETE': 'Delete the engagement',
    'HOME.TABLE.ACTION.TOOLTIP.CREATE': 'Create an engagement',
    'HOME.TABLE.ACTION.TOOLTIP.OPEN': 'Open the selected engagement',
    'HOME.TABLE.ACTION.TOOLTIP.ARCHIVE': 'Archive engagement',
    'HOME.TABLE.ACTION.TOOLTIP.UNARCHIVE': 'Unarchive engagement',
    'HOME.TABLE.FILTER.ENGAGEMENT.NAME.CODE.PLACEHOLDER': 'Name or Code'
};
