import { useIntl } from 'react-intl';
import React from 'react';
import { MZRSelectEngagementOption, MZRValueSelectedContainer } from '../../../helpers';
import { MZRSearchBar } from '../../common/MZRSearchBar';
import { VariableSizeList } from 'react-window'

const VirtualizedMenuList = (props) => {
    const listRef = React.useRef<any>();
    const { children } = props;
    listRef.current?.resetAfterIndex(0);

    const optionsData = React.Children.map(children, (child) => child.props.data);
    const optionsSize = optionsData.map((option) => {
            const numberCompanies = option?.clientCompanies?.length ?? 0;
            if (!numberCompanies) {
                return 85;
            } else {
                return 100 + 50 * numberCompanies;
            }
        });
    const itemSize = (option) => {
        return optionsSize[option]
    }
    return <VariableSizeList ref={listRef} height={500} itemCount={optionsSize.length} itemSize={itemSize} width={500}>
        {({ index, style }) => (
            <div style={style}>{children[index]}</div>
        )}
    </VariableSizeList>;
};


export const EngagementSelect = (props: any) => {
    const intl = useIntl();
    const { clientCompaniesVisible = true, handleChange, handleDelete } = props;

    const getOptionValue = (option: any) => {
        let value = `${option.name ?? ''} ${option.engagementCode ?? ''}`;

        return clientCompaniesVisible
            ? `${value} ${Object.values(option?.clientCompanies).map(
                  (company: any) =>
                      `${company.name} ${company.address} ${company.zipCode} ${company.city} ${company.countryText}`
              )}`.toLocaleLowerCase()
            : value;
    };

    const handleChangeValue = (selectedValue: any) => {
        props.onChange ? props.onChange(selectedValue) : handleChange(selectedValue);
    };

    return (
        <>
            {!props.hidden && (
                <MZRSearchBar
                    {...props}
                    fullWidth
                    id={props.id ?? props.name}
                    name={props.name}
                    isAreaFocusVisible={false}
                    clientCompaniesVisible={false}
                    components={{
                        Option: MZRSelectEngagementOption,
                        ValueContainer: MZRValueSelectedContainer,
                        MenuList: VirtualizedMenuList,
                    }}
                    disabled={props.disabled}
                    options={props.options}
                    onSelectionChange={handleChangeValue}
                    isClearable={true}
                    onClear={handleDelete}
                    getOptionValue={getOptionValue}
                    placeholder={intl.formatMessage({
                        id: 'DOCUMENT.SELECT.ENGAGEMENT.SEARCH_BAR.PLACEHOLDER'
                    })}
                />
            )}
        </>
    );
};
