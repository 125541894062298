export const home = {
    'DEVELOPMENT.WARNING.BANNER.MESSAGE':
        'Veuillez noter qu\'il s\'agit d\'un environement de test ! Pour accéder à la version de production, veuillez suivre le lien suivant: <a> https://confirmations.mazars.global</a>',
    'DEVELOPMENT.WARNING.BANNER.MAINTENANCE': '<b>⚠️ Maintenance programmée</b><br></br>L\'application sera indisponible le <b>Lundi 25 Novembre de 18h à 20h</b>. Merci pour votre compréhension.',

    'TRANSLATOR.SELECT': 'choisissez votre langue',

    'NAVBAR.WELCOME': 'Bonjour',
    'NAVBAR.SIGNOUT': 'Se déconnecter',
    'NAVBAR.SWITCH.PROFILE': 'Changer de profile',
    'NAVBAR.SELECT.AUDITOR': 'Auditeur',
    'NAVBAR.SELECT.ADMIN': 'Administrateur',
    'NAVBAR.SELECT.SUPPORT': 'Support',
    'NAVBAR.SELECT.CLIENT': 'Client',
    'NAVBAR.LANGUAGE.SELECT': 'Choix de la langue',

    'HOME.TITLE': 'Accueil',
    'HOME.ENGAGEMENT.BUTTON.CREATE': 'Nouvelle mission',
    'HOME.USER.PROFILE.ERROR': 'Erreur profil utilisateur',
    'HOME.USER.ENGAGEMENT.ERROR': 'Erreurs missions utilisateurs',
    'HOME.KPI.ENGAGEMENTS': 'Missions',
    'HOME.KPI.CLIENTS': 'Clients',
    'HOME.KPI.CONFIRMATIONS.CREATED': 'Circularisations créées',
    'HOME.KPI.CONFIRMATIONS.SENT': 'Circularisations envoyées',
    'HOME.TABLE.TITLE.ENGAGEMENT': 'Missions',
    'HOME.TABLE.TITLE.CLOSING_DATE': "Date d'arrêté des comptes",
    'HOME.TABLE.TITLE.COMPANIES': 'Entreprises/filiales',
    'HOME.TABLE.TITLE.USERS': 'Auditeurs',
    'HOME.TABLE.TITLE.PROGRESS': 'Progression',
    'HOME.TABLE.SUBTITLE.PROGRESS': 'Progression',
    'HOME.TABLE.ACTION.TOOLTIP.ARCHIVE': 'Archiver la mission',
    'HOME.TABLE.ACTION.TOOLTIP.UNARCHIVE': 'Désarchiver la mission',
    'HOME.TABLE.ACTION.TOOLTIP.SETTINGS': 'Paramètres de la mission',
    'HOME.TABLE.ACTION.TOOLTIP.NEW': 'Nouvelle circularisation',
    'HOME.TABLE.ACTION.TOOLTIP.CREATE': 'Créer une mission',

    'HOME.ENGAGEMENT.FILTER.ARCHIVED': 'Missions archivées',
    'HOME.ENGAGEMENT.FILTER.OPEN': 'Ouvrir mission',
    'HOME.USER.ENGAGEMENT.ARCHIVED.ERROR': 'Échec lors de l\'archivage de la mission',
    'HOME.ENGAGEMENT.ARCHIVE.ERROR': 'Échec lors de l\'archivage de la mission',
    'HOME.ENGAGEMENT.ARCHIVE.SUCCESS': 'Mission (dés)archivée',

    'HOME.TABLE.TITLE.NB_COMPANIES': 'Entités',
    'HOME.TABLE.TITLE.NB_CONFIRMATIONS': 'Circularisations',
    'HOME.TABLE.PROGRESS.NOT.STARTED': 'Non commencé',
    'HOME.TABLE.ACTION.TOOLTIP.DOWNLOAD': "Télécharger les documents de la missions",
    'HOME.TABLE.ACTION.TOOLTIP.DELETE': 'Supprimer la mission',
    'HOME.TABLE.ACTION.TOOLTIP.OPEN': 'Ouvrir la mission sélectionnée',
    'HOME.TABLE.FILTER.ENGAGEMENT.NAME.CODE.PLACEHOLDER': 'Nom ou code'
};
